
import { ref, defineComponent } from "vue";
import { common, isPaymentWarning } from "@/store/stateless/common";


  export default defineComponent({

  props: {
    title: {
        type: String,
        required: true
    },
    message: {
        type: String,
        required: true
    },
  },

  setup() {
    
    return {
      common, isPaymentWarning
    }
  }
  })

